import { createSlice } from '@reduxjs/toolkit';

// project imports
import { axiosMSTeamsCQService, axiosReportService } from 'utils/axios';
import { sortBy } from 'lodash';
import { updateOrgLevelAlias } from 'views/Reports/utils';
import { TemplateCategory } from 'views/Reports/Constants';

const initialState = {
    previewData: null,
    previewDataLoading: false,
    previewDataError: null,
    reportId: 0,
    runNowDataLoaded: false,
    templateName: '',
    runNowDataError: null,
    exportStart: false,
    isExportSuccess: false,
    selectedColumnsFromTemplate: [],
    selectedGroupingsFromTemplates: [],
    reportLoadingStart: false,
    reportDetails: {},
    queueRecordDetails: {},
    queueRecordLoading: false,
    summaryFieldsConfig: [],
    summaryReportData: [],
    reportSummaryDetails: {},
    summaryPreviewtData: [],
    summaryPreviewDetails: {},
    reportDataLoadingStart: false
};
const slice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        createExportRequest(state) {
            state.exportStart = true;
        },
        createExportSuccess(state) {
            state.exportStart = false;
            state.isExportSuccess = true;
        },
        createExportError(state) {
            state.exportStart = false;
        },

        createPreviewDataRequest(state) {
            state.reportDataLoadingStart = true;
            state.previewDataLoading = true;
            state.previewData = null;
            state.previewDataError = null;
        },

        createPreviewDataSuccess(state, action) {
            state.previewDataLoading = false;
            state.previewData = action.payload;
            state.previewDataError = null;
        },

        createPreviewDataError(state, action) {
            state.reportDataLoadingStart = false;
            state.previewDataLoading = false;
            state.previewData = null;
            state.previewDataError = action.payload;
        },

        createRunNowSuccess(state, action) {
            state.reportId = action.payload;
            state.reportLoadingStart = false;
        },
        createRunNowRequest(state, action) {
            state.reportLoadingStart = true;
            state.runNowDataError = null;
            state.reportId = 0;
        },
        createRunNowError(state, action) {
            state.runNowDataError = action.payload;
            state.reportLoadingStart = false;
            state.reportDataLoadingStart = false;
        },
        resetReportId(state, action) {
            state.reportId = 0;
            state.reportLoadingStart = false;
        },
        cleanUpExport(state, action) {
            state.exportStart = false;
            state.isExportSuccess = false;
        },
        resetTemplateName(state, action) {
            state.templateName = '';
        },
        resetPreviewDetails(state, action) {
            state.summaryPreviewDetails = {};
            state.summaryPreviewtData = [];
        },

        resetPreviewError(state, action) {
            state.previewDataError = null;
        },
        setTemplateName(state, action) {
            state.templateName = action.payload;
        },
        setReportParameter(state, action) {
            state.selectedColumnsFromTemplate = [...action.payload.selectedColumns];
            state.selectedGroupingsFromTemplates = [...action.payload.selectedGroupings];
        },
        setReportDetails(state, action) {
            state.reportDetails = {
                reportStartDate: action.payload.reportStartDate,
                reportStartTime: action.payload.reportStartTime,
                gmtOffsetInMinutes: action.payload.gmtOffsetInMinutes,
                reportEndDate: action.payload.reportEndDate,
                reportEndTime: action.payload.reportEndTime,
                templateName: action.payload.templateName,
                reportConfigDetails: action.payload.reportConfigDetails,
                summaryFieldsConfig: action.payload.reportSummaryFieldsConfiguration,
                reportSummaryDetails: action.payload.reportSummaryDetails
            };
        },
        setSummaryPreviewDetails(state, action) {
            state.reportDataLoadingStart = false;
            state.previewDataLoading = false;
            state.previewDataError = null;
            state.summaryPreviewtData = JSON.parse(action.payload.data.reportData);
            state.summaryPreviewDetails = {
                reportConfigDetails: action.payload.reportConfig,
                summaryFieldsConfig: action.payload.data.reportSummaryFieldsConfiguration,
                reportSummaryDetails: action.payload.data.reportSummaryDetails
            };
        },
        setQueueRecordDetails(state, action) {
            state.queueRecordDetails = action.payload;
            state.queueRecordLoading = false;
        },
        createQueueRecordDetails(state, action) {
            state.queueRecordLoading = true;
        },
        createQueueRecordError(state, action) {
            state.queueRecordLoading = false;
        },
        clearQueueRecordDetails(state, action) {
            state.queueRecordDetails = {};
            state.queueRecordLoading = false;
        },
        createSummaryDataRequest(state) {
            state.reportDataLoadingStart = true;
        },
        setSummaryReportData(state, action) {
            state.reportDataLoadingStart = false;
            state.summaryReportData = action.payload;
        }
    }
});

export default slice.reducer;

const getReportConfig = (reportConfig) => {
    return {
        dateFormat: reportConfig.dateFormats.filter((date) => date.isSelected)[0]?.format,
        isTwelveHourFormat: reportConfig.isTwelveHourFormat,
        timeFormat: reportConfig.includeSeconds ? 'hh:mm:ss' : 'hh:mm',
        durationFormat: reportConfig.durationFormat,
        includeSeconds: reportConfig.includeSeconds,
        internalCallOwnership: reportConfig.internalCallOwnership
    };
};

export function fetchPreviewData(enqueueSnackbar, payload, templateCategory) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createPreviewDataRequest());
            const response = await axiosReportService.post(`/ReportGeneration/preview`, payload);
            if (response?.data?.success) {
                if (response.data?.data?.reportData === '[]') enqueueSnackbar('No Data available', { variant: 'info' });
                if (templateCategory === TemplateCategory.DetailTemplate)
                    dispatch(slice.actions.createPreviewDataSuccess(JSON.parse(response.data.data.reportData)));
                if (templateCategory === TemplateCategory.SummaryTemplate)
                    dispatch(
                        slice.actions.setSummaryPreviewDetails({
                            data: response.data.data,
                            reportConfig: getReportConfig(payload?.reportParams?.reportConfig)
                        })
                    );
            } else {
                dispatch(slice.actions.createPreviewDataError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response.data.error) {
                dispatch(slice.actions.createPreviewDataError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            }
        }
    };
}

export function fetchReportDetails(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunNowRequest());
            const response = await axiosReportService.post(`/ReportGeneration/report`, payload);
            if (response?.data?.success) {
                dispatch(slice.actions.createRunNowSuccess(response.data.data.reportId));
            } else {
                dispatch(slice.actions.createRunNowError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response.data.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Session expired'));
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Error in getting Report Data'));
            }
        }
    };
}
export function setReportParameter(selectedColumns, selectedGroupings) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setReportParameter({ selectedColumns, selectedGroupings }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

const extractColumns = (fields, templateFields, dispatch, orgLevelDescription) => {
    templateFields = updateOrgLevelAlias(templateFields, orgLevelDescription);
    let selectedColumns = [];
    let selectedGroupings = [];

    templateFields.forEach((field) => {
        if (field.isSelectedField) selectedColumns.push(field);
        if (field.isGroupable) {
            if (field.isSelectedGroup) selectedGroupings.push({ ...field, category: 'Available Grouping' });
        }
    });
    selectedColumns = sortBy(selectedColumns, 'columnOrder');
    selectedGroupings = sortBy(selectedGroupings, 'groupOrder');

    dispatch(setReportParameter(selectedColumns, selectedGroupings));
};

const extractColumnsForReports = (fieldsAndGroups, dispatch, orgLevelDescription) => {
    fieldsAndGroups = updateOrgLevelAlias(fieldsAndGroups, orgLevelDescription);
    let selectedColumns = [];
    let selectedGroupings = [];

    fieldsAndGroups.forEach((field) => {
        selectedColumns.push(field);
        if (field.groupOrder > 0) {
            selectedGroupings.push({ ...field, category: 'Available Grouping' });
        }
    });
    selectedColumns = sortBy(selectedColumns, 'columnOrder');
    selectedGroupings = sortBy(selectedGroupings, 'groupOrder');

    dispatch(setReportParameter(selectedColumns, selectedGroupings));
};

export function fetchReportDetailsFromTemplates(enqueueSnackbar, payload, templateId, orgLevelDescription) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunNowRequest());
            const response = await axiosReportService.post(`/ReportGeneration/report/${templateId}`, payload);
            if (response?.data?.success) {
                const templateResponse = response.data?.data?.templateResponse;
                extractColumns(templateResponse?.fieldCategories, templateResponse.templateFields, dispatch, orgLevelDescription);
                dispatch(slice.actions.createRunNowSuccess(response.data.data.reportId));
            } else {
                dispatch(slice.actions.createRunNowError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response?.data?.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Session expired'));
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Error in getting Report Data'));
            }
        }
    };
}

export function fetchReportDetailsFromHistory(enqueueSnackbar, reportId, orgLevelDescription) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunNowRequest());
            const response = await axiosReportService.get(`/Reports/${reportId}`);
            if (response?.data?.success) {
                const reportFieldDetails = response.data?.data?.reportFieldDetails;
                dispatch(slice.actions.setReportDetails(response.data?.data));
                extractColumnsForReports(reportFieldDetails, dispatch, orgLevelDescription);
            } else {
                dispatch(slice.actions.createRunNowError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response?.data?.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Session expired'));
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Error in getting Report Data'));
            }
        }
    };
}
export function fetchSummaryReportData(enqueueSnackbar, reportId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createSummaryDataRequest());
            const payload = { offset: 0, count: 20000, reportParams: { filtersGroups: [], groups: [] } };
            const response = await axiosReportService.post(`/Reports/${reportId}/data`, payload);

            if (response?.data?.success) {
                const reportDataString = response.data?.data?.reportData;

                let result = [];
                if (reportDataString) {
                    try {
                        // Parse the stringified JSON array
                        const parsedData = JSON.parse(reportDataString);

                        if (Array.isArray(parsedData)) {
                            result = parsedData;
                        } else {
                            console.error('Parsed report data is not an array:', parsedData);
                        }
                    } catch (parseError) {
                        console.error('Failed to parse report data:', parseError);
                    }
                }
                dispatch(slice.actions.setSummaryReportData(result));
            } else {
                // dispatch(slice.actions.createSummaryDataError(response.data.error));
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
            }
        } catch (ex) {
            console.error('Error fetching summary report data:', ex);

            if (ex.response?.data?.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
                enqueueSnackbar(ex.response.data.error, { variant: 'error' });
            } else if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Session expired'));
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowError('Error in getting Report Data'));
            }
        }
    };
}

export function resetReportId() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetReportId());
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function clearQueueRecordDetails() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.clearQueueRecordDetails());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function cleanUpExport() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.cleanUpExport());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setTemplateName(templateName) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setTemplateName(templateName));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetTemplateName() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetTemplateName());
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function resetPreviewDetails() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetPreviewDetails());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetPreviewError() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetPreviewError());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function exportReportFile(enqueueSnackbar, reportId, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createExportRequest());
            const response = await axiosReportService.post(`/Reports/${reportId}/export`, payload);
            if (response?.data?.success) {
                dispatch(slice.actions.createExportSuccess(response.data.data.reportId));
            } else {
                dispatch(slice.actions.createExportError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response.data.error) {
                dispatch(slice.actions.createExportError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Report', { variant: 'error' });
            }
        }
    };
}

export function getQueueRecordDetails(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createQueueRecordDetails());
            const response = await axiosMSTeamsCQService.post('/api/DetailedDataAnalytics/getQueueRecordDetails', payload);
            if (response?.data?.success) {
                dispatch(slice.actions.setQueueRecordDetails(response.data?.data));
            } else {
                dispatch(slice.actions.createQueueRecordError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response?.data?.error) {
                dispatch(slice.actions.createQueueRecordError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createQueueRecordError());
            } else {
                enqueueSnackbar('Error in getting Data', { variant: 'error' });
                dispatch(slice.actions.createQueueRecordError());
            }
        }
    };
}

export function setQueueRecordLoading() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createQueueRecordDetails());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setRunNowError(error) {
    return async (dispatch) => {
        dispatch(slice.actions.createRunNowError(error));
    };
}
