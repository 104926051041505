import { useEffect, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { IconAlertTriangle, IconGripVertical, IconTrash } from '@tabler/icons';
import { v4 as uuidV4 } from 'uuid';

import { MetricCardConfig } from '../constants';
import ReactGridLayout from 'react-grid-layout';
import { useTheme, styled } from '@mui/material/styles';

// [metric]: {
//     displayName: ''
//     allowedCalculationMethods: {
//         [calculation]: {
//             displayName: ''
//         }
//     }
// }

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        fontSize: theme.typography.pxToRem(12),
        paddingRight: 16
    }
}));

export default function MetricsSubsetGrid({
    fieldsConfig,
    setFieldsConfig,
    allowedMetricsConfig,
    maxFieldCount,
    isReorderable = false,
    setHasError,
    disabled = false
}) {
    const [layout, setLayout] = useState(
        fieldsConfig.map((item, index) => ({
            x: 0,
            y: index,
            w: 1,
            h: 1,
            i: item.id,
            isResizable: false
        }))
    );
    const theme = useTheme();

    const [metricsErrorCollection, setMetricsErrorCollection] = useState({});

    const hasDuplicateDisplayAs = (newDisplayAs, columId) => {
        // Check if the new displayAs exists in any other row (excluding the current one)
        return fieldsConfig.some((column) => {
            return column.displayAs === newDisplayAs && column.id !== columId;
        });
    };

    const addNewColumnToConfig = () => {
        if (maxFieldCount && fieldsConfig.length === maxFieldCount) {
            return;
        }
        setFieldsConfig((c) => [
            ...c,
            {
                id: `temp-id-${uuidV4()}`,
                metric: '',
                calculationType: '',
                displayAs: '',
                displayOrder: c.length - 1
            }
        ]);
    };

    useEffect(() => {
        if (fieldsConfig.length === 0) {
            setHasError(true);
            return;
        }

        const invalidFieldIndexes = fieldsConfig
            .map((item) => {
                const errors = [];
                if (item.displayAs.length > 50) {
                    errors.push('Display As can have atmost 50 characters');
                }
                if (item.displayAs.length > 0 && hasDuplicateDisplayAs(item.displayAs, item.id)) {
                    errors.push('Display As should have unique value');
                }
                if (
                    !item.metric ||
                    !item.calculationType ||
                    !item.displayAs ||
                    !item.displayAs.trim() || // Check if displayAs exists before trimming
                    !Object.keys(allowedMetricsConfig[item.metric]?.allowedCalculationMethods || {})?.includes(item.calculationType)
                ) {
                    errors.push('All the fields must be filled');
                }
                return errors.length > 0 ? { id: item.id, errors } : null;
            })
            .filter((item) => item !== null)
            .reduce((acc, cur) => {
                const key = cur.id;
                acc[key] = cur.errors;
                return acc;
            }, {});

        if (Object.keys(invalidFieldIndexes).length > 0) {
            setMetricsErrorCollection(invalidFieldIndexes);
            setHasError(true);
        } else {
            setMetricsErrorCollection({});
            setHasError(false);
        }
    }, [fieldsConfig]);

    const updatefieldsConfig = (index, field, value) => {
        const newFieldsConfig = JSON.parse(JSON.stringify(fieldsConfig));
        newFieldsConfig[index][field] = value;
        if (field === 'metric') {
            const selectedMetric = value;
            const allowedCalculations = Object.keys(allowedMetricsConfig[selectedMetric]?.allowedCalculationMethods);
            const defaultCalculation = allowedCalculations.find(
                (calculation) => !fieldsConfig.some((field) => field.metric === selectedMetric && field.calculationType === calculation)
            );
            newFieldsConfig[index].calculationType = defaultCalculation;
        }
        if (field === 'metric' || field === 'calculationType') {
            const { metric, calculationType } = newFieldsConfig[index];
            newFieldsConfig[index].displayAs =
                allowedMetricsConfig[metric]?.allowedCalculationMethods?.[calculationType]?.shortName ?? MetricCardConfig[metric]?.label;
        }
        setFieldsConfig(newFieldsConfig);
    };

    const deleteColumn = (index) => {
        const newFieldsConfig = JSON.parse(JSON.stringify(fieldsConfig));
        newFieldsConfig.splice(index, 1);
        setFieldsConfig(newFieldsConfig);
    };

    const reorderRows = (layoutArray) => {
        const newFieldsConfig = fieldsConfig.toSorted((field1, field2) => {
            const field1IndexInLayout = layoutArray.findIndex((layoutObj) => layoutObj.i === field1.id?.toString());
            const field2IndexInLayout = layoutArray.findIndex((layoutObj) => layoutObj.i === field2.id?.toString());
            return layoutArray[field1IndexInLayout].y - layoutArray[field2IndexInLayout].y;
        });
        setFieldsConfig(newFieldsConfig);
        setLayout(layoutArray.map((item) => ({ ...item, isResizable: false })));
    };

    if (!fieldsConfig || !Array.isArray(fieldsConfig)) {
        return <div>No data for the selected date range and filters</div>;
    }

    return (
        <div>
            {maxFieldCount !== 1 && (
                <Button size="small" variant="contained" onClick={addNewColumnToConfig} style={{ marginBottom: 5 }}>
                    + Add Metric
                </Button>
            )}
            <div className="edit-metrics-table-dialog-content">
                <div className="edit-metrics-table-header">
                    <div className="edit-metrics-table-header-cell">
                        <p className="column-heading" style={theme.palette.mode === 'dark' ? { color: '#a0a9c0' } : null}>
                            Metric
                        </p>
                    </div>
                    <div className="edit-metrics-table-header-cell">
                        <p className="column-heading" style={theme.palette.mode === 'dark' ? { color: '#a0a9c0' } : null}>
                            Calculation
                        </p>
                    </div>
                    <div className="edit-metrics-table-header-cell">
                        <p className="column-heading" style={theme.palette.mode === 'dark' ? { color: '#a0a9c0' } : null}>
                            Display As
                        </p>
                    </div>
                </div>
                {fieldsConfig.length === 0 ? (
                    <div style={{ textAlign: 'center' }}>Select at least one metric to save</div>
                ) : (
                    <ReactGridLayout
                        layout={layout}
                        rowHeight={70}
                        width={500}
                        cols={1}
                        containerPadding={[0, 0]}
                        onLayoutChange={reorderRows}
                        className="layout"
                        draggableHandle="#drag-row"
                    >
                        {fieldsConfig.map((column, index) => {
                            return (
                                <div key={column.id}>
                                    <div className="edit-metrics-table-body" key={column.id}>
                                        <div className="edit-metrics-table-cell">
                                            <Select
                                                disabled={disabled}
                                                sx={{ width: 240 }}
                                                fullWidth
                                                id="metric-select"
                                                value={column.metric}
                                                onChange={(event) => updatefieldsConfig(index, 'metric', event.target.value)}
                                                error={!fieldsConfig[index].metric}
                                            >
                                                {Object.entries(allowedMetricsConfig || {}).map(([metricKey, metricValue], metricIndex) => {
                                                    // Optimize it
                                                    const associatedCalculations = Object.keys(metricValue.allowedCalculationMethods);
                                                    // Check if all associated calculations for the current metric are already selected
                                                    const allCalculationsSelected = associatedCalculations.every((calculation) =>
                                                        fieldsConfig.some(
                                                            (field) => field.metric === metricKey && field.calculationType === calculation
                                                        )
                                                    );
                                                    return (
                                                        <MenuItem
                                                            key={metricKey + metricIndex}
                                                            value={metricKey}
                                                            disabled={allCalculationsSelected}
                                                        >
                                                            {metricValue.displayName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                        <div className="edit-metrics-table-cell">
                                            <Select
                                                disabled={disabled}
                                                sx={{ width: 240 }}
                                                fullWidth
                                                value={column.calculationType}
                                                id="calculation-select"
                                                onChange={(event) => updatefieldsConfig(index, 'calculationType', event.target.value)}
                                                error={
                                                    !fieldsConfig[index].calculationType ||
                                                    !Object.keys(
                                                        allowedMetricsConfig[column.metric]?.allowedCalculationMethods || {}
                                                    )?.includes(fieldsConfig[index].calculationType)
                                                }
                                            >
                                                {!!allowedMetricsConfig[column.metric]?.allowedCalculationMethods &&
                                                    Object.keys(allowedMetricsConfig[column.metric]?.allowedCalculationMethods).map(
                                                        (item) => (
                                                            <MenuItem
                                                                disabled={fieldsConfig.some(
                                                                    (field) =>
                                                                        field.metric === column.metric && field.calculationType === item
                                                                )}
                                                                key={item}
                                                                value={item}
                                                            >
                                                                {
                                                                    allowedMetricsConfig[column.metric]?.allowedCalculationMethods?.[item]
                                                                        ?.displayName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                        <div className="edit-metrics-table-cell">
                                            <TextField
                                                sx={{ width: 240 }}
                                                fullWidth
                                                disabled={disabled}
                                                className="edit-metrics-table-cell"
                                                variant="outlined"
                                                value={column.displayAs}
                                                onChange={(event) => updatefieldsConfig(index, 'displayAs', event.target.value)}
                                                error={
                                                    !fieldsConfig[index].displayAs.trim() ||
                                                    fieldsConfig[index]?.displayAs.length > 50 ||
                                                    hasDuplicateDisplayAs(fieldsConfig[index].displayAs, column.id)
                                                }
                                            />
                                        </div>
                                        {maxFieldCount !== 1 && (
                                            <>
                                                <IconButton aria-label="Delete" onClick={() => deleteColumn(index)}>
                                                    <IconTrash />
                                                </IconButton>
                                                {isReorderable && (
                                                    <IconButton aria-label="Re-order" className="reorder-handle">
                                                        <IconGripVertical id="drag-row" />
                                                    </IconButton>
                                                )}
                                            </>
                                        )}
                                        <HtmlTooltip
                                            title={
                                                <ul style={{ color: 'white' }}>
                                                    {metricsErrorCollection[column.id]?.map((errorItem, errorIndex) => (
                                                        <li style={{ color: 'white' }} key={errorIndex}>
                                                            <Typography variant="caption" color="white" className="error-message">
                                                                {errorItem}
                                                            </Typography>
                                                        </li>
                                                    ))}
                                                </ul>
                                            }
                                        >
                                            {metricsErrorCollection && metricsErrorCollection[column.id] && (
                                                <IconButton>
                                                    <IconAlertTriangle color="red" />
                                                </IconButton>
                                            )}
                                        </HtmlTooltip>
                                    </div>
                                    {metricsErrorCollection && metricsErrorCollection[column.id] && (
                                        <div style={{ padding: '5px 0px' }}>
                                            <ul style={{ color: `${theme.palette.error.main}`, marginTop: '0px' }}>
                                                {metricsErrorCollection[column.id]?.map((errorItem, errorIndex) => (
                                                    <li key={errorIndex}>
                                                        <Typography variant="caption" color="error" className="error-message">
                                                            {errorItem}
                                                        </Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </ReactGridLayout>
                )}
            </div>
        </div>
    );
}
