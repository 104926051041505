export const Category = {
    QueueDataReport: 'QueueDataReport',
    CallDataReport: 'CallDataReport',
    AgentDataReport: 'AgentDataReport',
    QueueSummaryByHandlingTypeReport: 'QueueSummaryByHandlingTypeReport',
    QueueSummaryByFinalActionReport: 'QueueSummaryByFinalActionReport',
    QueueDailySummaryByHandlingTypeReport: 'QueueDailySummaryByHandlingTypeReport'
};

export const TemplateCategory = {
    SummaryTemplate: 'Summary',
    DetailTemplate: 'Detail'
};

export const Fields = {
    QueueHandlingType: 'QueueHandlingType',
    QueueSourceType: 'QueueSourceType',
    Agent: 'Agent',
    Queue: 'Queue',
    QueueFinalAction: 'QueueFinalAction',
    QueueDisposition: 'QueueDisposition',
    AgentFinalAction: 'AgentFinalAction',

    CallFinalAction: 'CallFinalAction',
    CallDirection: 'CallDirection'
};
export const GroupBy = {
    QueueAndDateGroups: 'QueueAndDateGroups',
    QueueOnly: 'QueueOnly'
};

export const SummeryType = {
    Breakout: 'Breakout',
    Time: 'Time',
    Percent: 'Percent'
};

export const DateGroup = {
    Quarter: 'Quarter',
    Month: 'Month',
    Week: 'Week',
    Date: 'Date',
    Hour: 'Hour'
};

export const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const BreakoutShowHideOptions = {
    HideBreakout: 'HideBreakoutWithNoData',
    ShowBreakout: 'ShowBreakoutWithNoData'
};

export const SummaryFieldNames = {
    Queue: 'Queue',
    DateTime: 'DateTime',
    ServiceLevel: 'ServiceLevel'
};

export const FormulaType = {
    Percentage: 'Percentage',
    WeightedAverage: 'WeightedAverage'
};

export const CommonTimeFormat = 'HH:mm:ss';
