import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import axios, { axiosRemoteApiService } from 'utils/axios';
import { useSnackbar } from 'notistack';
import useRouteAccess from 'routes/useRouteAccess';
import MainCard from 'ui-component/cards/MainCard';
import NoAccess from 'views/pages/NoAccess';
import useAuth from 'hooks/useAuth';

const RegisterRC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [remoteCollections, setRemoteCollections] = useState(null);
    const [remoteInfo, setRemoteInfo] = useState(null);
    const [rcValidateError, setRcValidateError] = useState(null);
    const [selectedRC, setSelectedRC] = useState(null);
    const hasAccessToCurrentRoute = useRouteAccess();
    const verificationToken = JSON.parse(window.localStorage.getItem('loginToken'));
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const handleRcRegister = async (collection, isValidate) => {
        // check for a valid remoteInfo. If not, display an error.
        if (remoteInfo) {
            const url = isValidate
                ? `/connectors/remote-collections/${collection.id}/register?validate=true`
                : `/connectors/remote-collections/${selectedRC.id}/register?validate=false`;
            const response = await axios.post(url, remoteInfo).catch(() => {
                navigate('/register/cloud-connect/failure', { state: { error: 'An unexpected error occured' }, replace: true });
            });
            if (response?.data?.success) {
                // if no data and there's a message, display the message
                if (!response.data.data && response.data.message) {
                    // this means validation failed. so display the appropriate message and give the choice of continue or go back
                    setSelectedRC(collection);
                    setRcValidateError({ rcName: collection.remoteName, message: response.data.message });
                } else if (response.data.data) {
                    // make the next call to remoteApi to save the registration
                    const payload = {
                        Tenant: response.data.data.tenantId,
                        Target: 'RemoteConfig',
                        Request: 'ConfigSignalR',
                        Remote: remoteInfo.rcid,
                        Client: user.clientId,
                        Data: {
                            AuthServiceUrl: process.env.REACT_APP_API_BASE_URL,
                            AuthServiceSub: process.env.REACT_APP_API_SUBSCRIPTION,
                            VerificationToken: verificationToken.token,
                            AuthUserName: response.data.data.authUserName,
                            AuthPassword: response.data.data.authUserPassword,
                            SignalRhostUrl: response.data.data.remoteHostURL,
                            SignalRhostSub: response.data.data.remoteHostSUB
                        }
                    };
                    const registerResponse = await axiosRemoteApiService.post(`/api`, payload).catch((error) => {
                        console.log(`Error - ${error.message}`);
                    });

                    // if that call is successful, navigate to Registration complete screen
                    if (registerResponse?.data?.result) {
                        navigate('/register/cloud-connect/success', { replace: true });
                    } else {
                        navigate('/register/cloud-connect/failure', {
                            state: { error: response.data?.message || 'An unexpected error occured' }
                        });
                    }
                }
            } else {
                navigate('/register/cloud-connect/failure', {
                    state: { error: response.data?.message || 'An unexpected error occured' }
                });
            }
        } else {
            navigate('/register/cloud-connect/failure', { state: { error: 'An unexpected error occured' } });
        }
    };

    useEffect(() => {
        const fetchRemoteInfo = async () => {
            const payload = {
                Target: 'RemoteConfig',
                Request: 'GetRemoteId',
                Data: {
                    AuthServiceUrl: process.env.REACT_APP_API_BASE_URL,
                    AuthServiceSub: process.env.REACT_APP_API_SUBSCRIPTION,
                    VerificationToken: verificationToken.token
                }
            };

            const response = await axiosRemoteApiService.post(`/api`, payload).catch((error) => {
                console.log(`Error - ${error.message}`);
            });

            if (response?.data) {
                setRemoteInfo({ rcid: response.data.RemoteId, remoteHostName: response.data.HostName });
            }
        };

        const fetchRcData = async () => {
            try {
                const response = await axios.get('/connectors/remote-collections');
                if (!response?.data?.success) {
                    enqueueSnackbar(response.data.message, { variant: 'error' });
                }
                if (response?.data?.success && response?.data.data) {
                    setRemoteCollections(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchRemoteInfo();
        fetchRcData();
    }, []);

    return (
        <AuthWrapper1>
            {!hasAccessToCurrentRoute ? (
                <MainCard>
                    <NoAccess hideDashboard />
                </MainCard>
            ) : (
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                <AuthCardWrapper>
                                    {rcValidateError ? (
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={12} sx={{ pb: 1 }}>
                                                <Typography variant="h3" gutterBottom>
                                                    {rcValidateError.rcName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">{rcValidateError.message}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    id="continueBtn"
                                                    color="primary"
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    onClick={() => handleRcRegister(null, false)}
                                                >
                                                    Continue
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="Text"
                                                    fullWidth
                                                    sx={{ height: 'fit-content', color: theme.palette.error.main }}
                                                    onClick={() => setRcValidateError(null)}
                                                >
                                                    Go Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            {remoteCollections && (
                                                <Grid item xs={12} sx={{ pb: 1 }}>
                                                    {remoteCollections.length > 0 ? (
                                                        <Typography variant="h3" gutterBottom>
                                                            Select Cloud Connect Server
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="h6">
                                                            No Cloud Connect Servers configured. Please login and create a Cloud Connect
                                                            Server
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                {remoteCollections ? (
                                                    remoteCollections?.map((collection, index) => (
                                                        <Card
                                                            key={index}
                                                            variant="outlined"
                                                            sx={{
                                                                mb: 2,
                                                                backgroundColor: theme.palette.mode === 'dark' ? '#29314f' : '#f5f5f5',
                                                                borderColor: '#9e9e9e',
                                                                cursor: 'pointer',
                                                                ':hover': {
                                                                    backgroundColor: theme.palette.mode === 'dark' ? '#384055' : '#e0e0e0'
                                                                }
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleRcRegister(collection, true);
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Grid container flexDirection="column">
                                                                    <Grid item sx={{ pb: 1 }}>
                                                                        <Typography variant="h5">{collection.remoteName}</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography color="text.secondary">
                                                                            {collection.rcid ? 'Registered' : 'Unregistered'}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    ))
                                                ) : (
                                                    <CircularProgress
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: -5,
                                                            marginLeft: -3,
                                                            zIndex: 999
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </AuthCardWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            )}
        </AuthWrapper1>
    );
};

export default RegisterRC;
