import { createSlice } from '@reduxjs/toolkit';
import { sortBy, template } from 'lodash';
// project imports
import { axiosReportService } from 'utils/axios';
import { FilterType } from 'utils/filterUtils';
import { updateOrgLevelAlias } from 'views/Reports/utils';
import { setTitle } from './breadcrumb';
import { setTemplateName } from './report';
import { displayName } from 'react-quill';

export const initialState = {
    originalTemplateData: null,
    templateData: null,
    templateDataError: null,
    dataLoaded: false,
    availableColumns: [],
    availableFilters: [],
    availableGroupings: [],
    selectedColumns: [],
    selectedGroupings: [],
    selectedFilters: [],
    categoryMap: {},
    dateTimeFilter: {},
    configDetails: {},
    isTemplateDataLoadingStart: false,
    hasUnsavedTemplateChanges: false,
    selectedFeatureType: 0,
    templateSummaryDetails: {},
    summarySortByOptions: [],
    lowestDateGroup: ''
};

const slice = createSlice({
    name: 'reportParameters',
    initialState,
    reducers: {
        createTemplateDataSuccess(state, action) {
            state.originalTemplateData = action.payload;
            state.templateData = action.payload;
            state.templateDataError = null;
            state.dataLoaded = true;
            state.configDetails = action.payload?.templateConfigDetails;
            state.templateSummaryDetails = action.payload?.templateSummaryDetails;
            state.summarySortByOptions = [
                { displayName: 'Queue', value: 'Queue', isSelected: true },
                { displayName: 'Other', value: 'Queue', isSelected: false }
            ];
        },
        createTemplateDataError(state, action) {
            state.templateData = null;
            state.templateDataError = action.payload;
            state.dataLoaded = true;
        },

        fetchTemplateDataStart(state, action) {
            state.isTemplateDataLoadingStart = true;
        },
        resetTemplateLoading(state, action) {
            state.isTemplateDataLoadingStart = false;
        },
        setReportParameter(state, action) {
            state.availableColumns = [...action.payload.availableColumns];
            state.availableGroupings = [...action.payload.availableGroupings];
            state.selectedColumns = [...action.payload.selectedColumns];
            state.selectedGroupings = [...action.payload.selectedGroupings];
            state.categoryMap = { ...action.payload.categoryMap };
        },
        setReportParameterAndFilters(state, action) {
            state.availableColumns = [...action.payload.availableColumns];
            state.availableGroupings = [...action.payload.availableGroupings];
            state.selectedColumns = [...action.payload.selectedColumns];
            state.selectedGroupings = [...action.payload.selectedGroupings];
            state.categoryMap = { ...action.payload.categoryMap };
            state.selectedFilters = [...state.selectedFilters, ...action.payload.filters];
            state.availableFilters = [...action.payload.availableFilters];
        },

        setSelectedColumns(state, action) {
            state.selectedColumns = action.payload;
        },
        setSelectedGrouping(state, action) {
            state.selectedGroupings = action.payload;
        },
        setDateTimeFilter(state, action) {
            state.dateTimeFilter = action.payload;
        },

        setAvailableColumns(state, action) {
            state.availableColumns = action.payload.availableColumns;
            if (action.payload.items) {
                if (action.payload.isColumn) {
                    state.selectedColumns = action.payload.items;
                } else {
                    state.selectedGroupings = action.payload.items;
                }
            }
        },
        setAvailableGroups(state, action) {
            state.availableGroupings = action.payload;
        },
        setTemplateFilters(state, action) {
            state.selectedFilters = action.payload;
        },
        setAvailableFilters(state, action) {
            state.availableFilters = action.payload;
        },
        resetTemplateData(state) {
            state.templateData = null;
            state.dataLoaded = false;
            state.availableColumns = [];
            state.availableGroupings = [];
            state.selectedColumns = [];
            state.selectedGroupings = [];
            state.categoryMap = {};
            state.selectedFilters = [];
            state.hasUnsavedTemplateChanges = false;
            state.selectedFeatureType = 0;
            state.templateSummaryDetails = {};
            state.lowestDateGroup = '';
        },
        updateIncludeSeconds(state, action) {
            state.configDetails.includeSeconds = action.payload;
        },
        updateHourFormat(state, action) {
            state.configDetails.isTwelveHourFormat = action.payload === '12-Hour';
        },
        updateCallOwnerShip(state, action) {
            state.configDetails.internalCallOwnership = action.payload;
        },
        updateDurationFormat(state, action) {
            state.configDetails.durationFormat = action.payload;
        },
        updateDateFormat(state, action) {
            const dateFormats = [];
            state.configDetails.dateFormats.forEach((dateFormat) => {
                if (dateFormat.format === action.payload) {
                    const format = {};
                    format.format = dateFormat.format;
                    format.isSelected = true;
                    dateFormats.push(format);
                } else {
                    const format = {};
                    format.format = dateFormat.format;
                    format.isSelected = false;
                    dateFormats.push(format);
                }
            });
            state.configDetails.dateFormats = dateFormats;
        },
        setHasUnsavedTemplateChanges(state, action) {
            state.hasUnsavedTemplateChanges = action.payload;
        },
        setSelectedFeatureType(state, action) {
            state.selectedFeatureType = action.payload;
        },
        setSummaryFields(state, action) {
            state.templateSummaryDetails.summaryFields = action.payload;
        },
        setSummaryDetailsCountFlags(state, action) {
            state.templateSummaryDetails.summaryDetails.includeCount = action.payload.countFlag;
            state.templateSummaryDetails.summaryDetails.includeCountPercent = action.payload.countPercentFlag;
        },
        setSummaryDetailsIncludeTimeFor(state, action) {
            state.templateSummaryDetails.summaryDetails.includeTimesFor.selectedValue = action.payload;
        },
        setSummaryGroupGroupBy(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryGroupOptions.groupBy.selectedValue = action.payload;
        },
        setSummaryGroupDateOrientation(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryGroupOptions.showDateGroupsAs.selectedValue = action.payload;
        },
        setSummaryGroupIncludeDateGroups(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryGroupOptions.dateGroupsToInclude.selectedValues = action.payload;
        },
        setSummaryDisplaySortType(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryDisplayOptions.sortType.selectedValue = action.payload;
        },
        setSortBy(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryDisplayOptions.sortBy = action.payload;
        },
        setSummaryDisplayBreakout(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryDisplayOptions.breakoutOptions.selectedValue = action.payload;
        },
        setSummaryDisplayShowQueue(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryDisplayOptions.showQueueAs.selectedValue = action.payload;
        },
        setSummaryDisplaySummary(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryDisplayOptions.summaryAs.selectedValue = action.payload;
        },
        setSummaryDisplaySummaryOrder(state, action) {
            state.templateSummaryDetails.summaryDetails.summaryDisplayOptions.summaryOrder.selectedValue = action.payload;
        },
        setSummarySortByOptions(state, action) {
            state.summarySortByOptions = action.payload;
        },
        setLowestDateGroup(state, action) {
            state.lowestDateGroup = action.payload;
        }
    }
});

export default slice.reducer;

export function setTemplateFilters(payload) {
    return async (dispatch) => {
        dispatch(slice.actions.setTemplateFilters(payload));
    };
}

export function setAvailableFilters(payload) {
    return async (dispatch) => {
        dispatch(slice.actions.setAvailableFilters(payload));
    };
}

export function setReportParameter(availableColumns, availableGroupings, selectedColumns, selectedGroupings, categoryMap) {
    return async (dispatch) => {
        try {
            dispatch(
                slice.actions.setReportParameter({ availableColumns, availableGroupings, selectedColumns, selectedGroupings, categoryMap })
            );
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setReportParameterAndFilters(
    availableColumns,
    availableGroupings,
    selectedColumns,
    selectedGroupings,
    categoryMap,
    filters,
    availableFilters
) {
    return async (dispatch) => {
        try {
            dispatch(
                slice.actions.setReportParameterAndFilters({
                    availableColumns,
                    availableGroupings,
                    selectedColumns,
                    selectedGroupings,
                    categoryMap,
                    filters,
                    availableFilters
                })
            );
        } catch (ex) {
            console.log(ex);
        }
    };
}

const updateTemplateFieldsBasedOnDirectory = (templateFields, directoryCustomAttribute, dirctoryCatId) => {
    const filedsBasedOnDirectory = [];
    templateFields.forEach((field) => {
        if (field.categoryId === dirctoryCatId) {
            const customAttr = directoryCustomAttribute?.find((attr) => attr?.code?.toUpperCase() === field.name.toUpperCase());
            if (customAttr) {
                if (customAttr.enabled) {
                    filedsBasedOnDirectory.push(field);
                }
            } else {
                filedsBasedOnDirectory.push(field);
            }
        } else {
            filedsBasedOnDirectory.push(field);
        }
    });
    return filedsBasedOnDirectory;
};

export function generateFiltersSkeleton(fieldIds) {
    const filterPayload = [];
    fieldIds.forEach((fieldId) => {
        filterPayload.push({
            fieldId,
            operator: 'Equals',
            values: [],
            logicalOperator: 'AND',
            isFilterRequired: true
        });
    });
    return filterPayload;
}

export const extractColumns = (
    fieldCategories,
    templateFields,
    templateFilters,
    dispatch,
    orgLevelDescription,
    directoryCustomAttribute,
    templateCategory
) => {
    const dirctoryCatId = fieldCategories.find((cat) => cat.categoryName === 'Directory')?.categoryId;
    // update templateFields based on directoryCustomAttribute
    if (dirctoryCatId) templateFields = updateTemplateFieldsBasedOnDirectory(templateFields, directoryCustomAttribute, dirctoryCatId);
    templateFields = updateOrgLevelAlias(templateFields, orgLevelDescription);
    templateFields = templateFields.map((item) => ({
        ...item,
        displayOrder: fieldCategories.find((cat) => cat.categoryId === item.categoryId).displayOrder
    }));
    templateFields = sortBy(templateFields, ['displayOrder', 'fieldDisplayOrder']);
    const availableColumns = templateFields;
    const availableFilters = templateFields.filter((field) => field?.isFilterable);
    fieldCategories = sortBy(fieldCategories, 'displayOrder');
    const availableGroupings = [];
    const selectedColumns = [];
    const selectedGroupings = [];
    const categoryMap = {};

    fieldCategories.forEach((category) => {
        categoryMap[category.displayOrder] = category.categoryName;
    });

    const fieldsSortedByColumnOrder = sortBy(templateFields, 'columnOrder');
    fieldsSortedByColumnOrder.forEach((field) => {
        if (field.isSelectedField) selectedColumns.push(field);
    });

    const fieldsSortedByGroupOrder = sortBy(templateFields, 'groupOrder');
    fieldsSortedByGroupOrder.forEach((field) => {
        if (field.isSelectedGroup) selectedGroupings.push({ ...field, category: 'Available Grouping' });
    });

    templateFields.forEach((field) => {
        if (field.isGroupable) {
            availableGroupings.push({ ...field, category: 'Available Grouping' });
        }
    });
    let filters = [];
    if (templateCategory === 'Summary') {
        let requiredFilterIds = templateFields?.filter((f) => f.isFilterRequired).map((f) => f.fieldId);
        if (templateFilters && templateFilters.length > 0) {
            const selectedFiltersIDs = templateFilters.map((sf) => sf.fieldId);
            requiredFilterIds = requiredFilterIds.filter((rf) => !selectedFiltersIDs.includes(rf));
        }
        const requiredFilters = generateFiltersSkeleton(requiredFilterIds);
        filters.push(...requiredFilters);
    }
    const directoryFieldId = templateFields?.find((selectedColumn) => selectedColumn.name === 'Directory')?.fieldId;
    const durationFieldIds = templateFields
        ?.filter((selectedColumn) => selectedColumn.filterType === FilterType.duration)
        ?.map((ele) => ele.fieldId);
    templateFilters.forEach((filter) => {
        const { name, isFilterRequired } = templateFields?.find((selectedColumn) => selectedColumn.fieldId === filter.fieldId);
        filter = { ...filter, fieldName: name, isFilterRequired };
        if (name === 'Name1' || name === 'Name2' || name === 'Name3' || name === 'Name4') {
            let directoryFilter = filters?.find((selectedColumn) => selectedColumn.fieldId === directoryFieldId);
            if (directoryFilter) {
                const clonedObject = [...directoryFilter.values];
                clonedObject?.push(...filter.values);
                const clonedData = [...directoryFilter.data];
                const d = clonedData?.find((data) => data.fieldId === filter.fieldId);
                if (d) {
                    const clonedValues = [...d.values];
                    clonedValues?.push(...filter);
                } else {
                    clonedData?.push(...[filter]);
                }

                directoryFilter = { ...directoryFilter, values: clonedObject, data: clonedData };

                filters = filters.filter((e) => {
                    return e.fieldId !== directoryFieldId;
                });

                filters.push(directoryFilter);
            } else {
                const newFilter = {
                    fieldId: directoryFieldId,
                    logicalOperator: filter.logicalOperator,
                    operator: filter.operator,
                    values: filter.values
                };
                newFilter.data = [filter];
                filters.push(newFilter);
            }
        } else {
            const d = durationFieldIds?.find((durationFieldId) => durationFieldId === filter.fieldId);
            if (d) {
                const newValues = [];
                for (let j = 0; j < filter.values?.length; j += 1) {
                    let durationInSeconds = filter.values[j]?.value;
                    let hours = Math.floor(durationInSeconds / 3600);
                    if (hours <= 9) {
                        hours = `0${hours}`;
                    }
                    durationInSeconds %= 3600;
                    let minutes = Math.floor(durationInSeconds / 60);
                    if (minutes <= 9) {
                        minutes = `0${minutes}`;
                    }
                    let seconds = durationInSeconds % 60;
                    if (seconds <= 9) {
                        seconds = `0${seconds}`;
                    }
                    newValues.push({ id: filter.values[j]?.id, value: `${hours}:${minutes}:${seconds}` });
                }
                filter = {
                    ...filter,
                    values: newValues,
                    filterType: FilterType.duration,
                    fieldName: name
                };
            }
            filters.push(filter);
        }
    });
    dispatch(
        setReportParameterAndFilters(
            availableColumns,
            availableGroupings,
            selectedColumns,
            selectedGroupings,
            categoryMap,
            filters,
            availableFilters
        )
    );
};

export function fetchTemplateData(enqueueSnackbar, templateId, orgLevelDescription, directoryCustomAttribute) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.fetchTemplateDataStart());

            const response = await axiosReportService.get(`/templates/${templateId}`);
            if (response?.data?.success) {
                if (response.data?.data?.templateName) {
                    dispatch(setTitle(response.data?.data?.templateName));
                    dispatch(setTemplateName(response.data?.data?.templateName));
                }

                const templateData = response.data.data;
                dispatch(slice.actions.createTemplateDataSuccess(templateData));

                extractColumns(
                    templateData.fieldCategories,
                    templateData.templateFields,
                    templateData.templateFilters,
                    dispatch,
                    orgLevelDescription,
                    directoryCustomAttribute,
                    templateData.templateCategory
                );
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(slice.actions.createTemplateDataError(ex.response));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting data', { variant: 'error' });
            }
        }
    };
}

export function setSelectedColumnsData(selectedColumns) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSelectedColumns(selectedColumns));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function updateIncludeSeconds(value) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateIncludeSeconds(value));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function updateHourFormat(value) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateHourFormat(value));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function updateDurationFormat(value) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDurationFormat(value));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDateFormat(value) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDateFormat(value));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function updateCallOwnerShip(value) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateCallOwnerShip(value));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setSelectedGroupingData(selectedGroupings) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSelectedGrouping(selectedGroupings));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setDateTimeFilter(dateTimeFilter) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setDateTimeFilter(dateTimeFilter));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setAvailableColumnsData(isColumn, items, availableColumns) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setAvailableColumns({ isColumn, items, availableColumns }));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setAvailableGroupsData(availableGroups) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setAvailableGroups(availableGroups));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setSelectedTemplateDetails(templateId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetTemplateData());
            dispatch(slice.actions.createSelectedTemplateId({ templateId }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetTemplateData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetTemplateData());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetTemplateLoading() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetTemplateLoading());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setUnsavedTemplateChanges(hasChanges) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setHasUnsavedTemplateChanges(hasChanges));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setSelectedFeatureType(featureType) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSelectedFeatureType(featureType));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setUpdateSummaryFields(summaryFields) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSummaryFields(summaryFields));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setSummaryDetailCountFlag(countFlag, countPercentFlag) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSummaryDetailsCountFlags({ countFlag, countPercentFlag }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setSummaryDetailsIncludeTimeFor(selectedValue) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSummaryDetailsIncludeTimeFor(selectedValue));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setSummaryGroupGroupBy(selectedValue) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSummaryGroupGroupBy(selectedValue));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setSummaryGroupDateOrientation(selectedValue) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSummaryGroupDateOrientation(selectedValue));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setSummaryGroupIncludeDateGroups(selectedValue) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setSummaryGroupIncludeDateGroups(selectedValue));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setLowestDateGroup(dateGroup) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setLowestDateGroup(dateGroup));
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function setSummaryDisplayOptions(selectedValue, type) {
    return async (dispatch) => {
        try {
            switch (type) {
                case 'sortType':
                    dispatch(slice.actions.setSummaryDisplaySortType(selectedValue));
                    break;
                case 'sortBy':
                    dispatch(slice.actions.setSortBy(selectedValue));
                    break;
                case 'breakout':
                    dispatch(slice.actions.setSummaryDisplayBreakout(selectedValue));
                    break;
                case 'showQueue':
                    dispatch(slice.actions.setSummaryDisplayShowQueue(selectedValue));
                    break;
                case 'summary':
                    dispatch(slice.actions.setSummaryDisplaySummary(selectedValue));
                    break;
                case 'summaryOrder':
                    dispatch(slice.actions.setSummaryDisplaySummaryOrder(selectedValue));
                    break;
                default:
                    console.log('Incorrect summary display type');
            }
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function fetchSummarySortByOptions(enqueueSnackbar, requestPayload) {
    return async (dispatch) => {
        try {
            const response = await axiosReportService.post('/templateSummary/generateFieldNames', requestPayload);
            if (response?.data?.success) {
                dispatch(slice.actions.setSummarySortByOptions(response.data.data));
            } else {
                enqueueSnackbar('Error in getting data', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting data', { variant: 'error' });
            }
        }
    };
}
