import { useContext, useState, useEffect, useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useFormik } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useTheme } from '@emotion/react';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import MetricsSubsetGrid from '../MetricsSubsetGrid/MetricsSubsetGrid';
import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import { DefaultDataGroupingOption, TimeSeriesMetricsCategory, TimeSeriesWidgetChartTypes } from '../constants';

import AreaChartLogo from 'assets/images/icons/noun-area-chart.svg';
import AreaChartDarkLogo from 'assets/images/icons/noun-area-chart-dark.svg';
import LineChartLogo from 'assets/images/icons/noun-line-graph.svg';
import LineChartDarkLogo from 'assets/images/icons/noun-line-graph-dark.svg';
import StackedBarChartLogo from 'assets/images/icons/noun-stacked-bar-chart.svg';
import StackedBarChartDarkLogo from 'assets/images/icons/noun-stacked-bar-chart-dark.svg';
import StackedBarChartHorizontalLogo from 'assets/images/icons/noun-stacked-bar-chart-horizontal.svg';
import StackedBarChartHorizontalDarkLogo from 'assets/images/icons/noun-stacked-bar-chart-horizontal-dark.svg';
import DateTimeFilter, { DateRangeType, RelativeDateUnitOptions } from '../DateTimeFilter/DateTimeFilter';
import { getUtcOffsetInMinutes } from 'utils/QueueConfigurationFormatAndValidation';
import { useSelector } from 'store';
import * as Yup from 'yup';
import { IconAlertTriangle } from '@tabler/icons';

const ViewTabs = {
    Metrics: 'metrics',
    DateTime: 'dateTime'
};

const getIconForChartType = (type, theme) => {
    switch (type) {
        case TimeSeriesWidgetChartTypes.Area:
            return theme === 'dark' ? AreaChartDarkLogo : AreaChartLogo;
        case TimeSeriesWidgetChartTypes.HorizontalBar:
            return theme === 'dark' ? StackedBarChartHorizontalDarkLogo : StackedBarChartHorizontalLogo;
        case TimeSeriesWidgetChartTypes.VerticalBar:
            return theme === 'dark' ? StackedBarChartDarkLogo : StackedBarChartLogo;
        default:
            return theme === 'dark' ? LineChartDarkLogo : LineChartLogo;
    }
};

export default function EditTimeSeriesWidgetDialog({
    isEditTimeSeriesDialogOpen,
    toggleEditTimeSeriesDialogOpen,
    widget,
    onWidgetConfigUpdate
}) {
    const validationSchemaForFields = Yup.object().shape({
        widgetName: Yup.string().trim().max(50, 'Widget name should not exceed 50 characters').required('Widget Name is required')
    });
    const { allowedTimeseriesWidgetConfig } = useSelector((state) => state.queueDashboard);
    const { filteredQueuesMetricsSummary, selectedQueueId } = useContext(DashboardStateContext);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const theme = useTheme();

    const [activeTab, setActiveTab] = useState(ViewTabs.Metrics);
    const [fieldsConfig, setFieldsConfig] = useState(widget.metrics);
    const [metricSubsetError, setMetricSubsetError] = useState(false);
    const [hasDateTimeErrors, setHasDateTimeErrors] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);

    useEffect(() => {
        setSaveDisabled(hasDateTimeErrors || metricSubsetError);
    }, [hasDateTimeErrors, metricSubsetError]);

    const selectedQueue = filteredQueuesMetricsSummary.find((q) => q.queueId === selectedQueueId);
    const formik = useFormik({
        initialValues: {
            id: widget.config.id,
            maxMetricsPerRow: widget.config.maxMetricsPerRow,
            widgetName: widget.name,
            type: widget.config.metricCategory,
            graphType: widget.config.graphType,
            groupTimeBy: widget.config.groupTimeBy,
            overrideDashboardDateTime: (widget.config.dateTimeFilter && true) ?? false,
            dateTimeFilter: widget.config.dateTimeFilter,
            yAxisCaption: widget.config.yAxisCaption,
            groupDataBy: widget.config.groupDataBy ?? DefaultDataGroupingOption.TimeSeries
        },
        validationSchema: validationSchemaForFields,

        onSubmit: (values) => {
            const { widgetName, type, overrideDashboardDateTime, dateTimeFilter, groupTimeBy, groupDataBy, ...rest } = values;
            const payload = {
                widgetId: widget.id,
                name: widgetName,
                metrics:
                    type === TimeSeriesMetricsCategory.QueueCallCounts
                        ? null
                        : fieldsConfig.map((metric, index) => ({ ...metric, displayOrder: index })),
                config: {
                    groupTimeBy,
                    metricCategory: type,
                    groupDataBy: type === TimeSeriesMetricsCategory.QueueCallCounts ? groupDataBy : null,
                    dateTimeFilter: overrideDashboardDateTime ? dateTimeFilter : null,
                    ...rest
                }
            };
            dispatchDashboardStateAction({
                type: DashboardStateActions.UpdateWidget,
                payload
            });
            dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });
            onWidgetConfigUpdate(payload);
            toggleEditTimeSeriesDialogOpen();
        }
    });

    const setYAxisCaption = (type, groupTimeBy) => {
        formik.setFieldValue(
            'yAxisCaption',
            type.toLowerCase().includes('count') ? `# calls per ${groupTimeBy.slice(0, -1)}` : 'length of time'
        );
    };

    const getDefaultFieldsConfigForMetricCategory = (value) => {
        if (value === TimeSeriesMetricsCategory.CallsAnsweredByAgent) {
            const allowedMetrics =
                allowedTimeseriesWidgetConfig.allowedMetricCategories[TimeSeriesMetricsCategory.CallsAnsweredByAgent]?.allowedMetrics;
            return [
                {
                    id: `reset-temp-id`,
                    metric: Object.keys(allowedMetrics)[0] ?? 'answeredCalls',
                    calculationType: Object.keys(Object.values(allowedMetrics)[0]?.allowedCalculationMethods ?? [])[0] ?? 'count',
                    displayAs: Object.values(allowedMetrics)[0]?.displayName,
                    displayOrder: 0
                }
            ];
        }
        return [
            {
                id: `reset-temp-id`,
                metric: '',
                calculationType: '',
                displayAs: '',
                displayOrder: 0
            }
        ];
    };

    const handleMetricCategoryTypeChange = (event) => {
        formik.setFieldValue('type', event.target.value);
        formik.setFieldValue('graphType', TimeSeriesWidgetChartTypes.Line);
        setYAxisCaption(event.target.value, formik.values.groupTimeBy);

        setFieldsConfig(
            event.target.value === widget.config.metricCategory
                ? widget.metrics
                : getDefaultFieldsConfigForMetricCategory(event.target.value)
        );
        // When metric category changes, reset metric subset errors as new metrics rules will apply
        setMetricSubsetError(false);
    };

    const setGroupTimeBy = (event) => {
        formik.setFieldValue('groupTimeBy', event.target.value);
        setYAxisCaption(formik.values.type, event.target.value);
    };

    const setDateTimeFilterValues = (values) => {
        const updatedDateTimeFilter = {
            type: values.type,
            date: {},
            time: {},
            countryCode: values.countryCode,
            utcOffsetInMinutes: getUtcOffsetInMinutes(values.utcOffsetInMinutes),
            useDst: values.useDst
        };

        if (values.type === DateRangeType.CurrentQueuePeriod) {
            updatedDateTimeFilter.date = null;
            updatedDateTimeFilter.time = null;
        }

        if (values.type === DateRangeType.Specific) {
            updatedDateTimeFilter.date = {
                specific: {
                    startDate: values.startDate,
                    endDate: values.endDate
                },
                relative: null,
                weekDays: values.weekDays
            };
        }

        if (values.type === DateRangeType.Relative) {
            updatedDateTimeFilter.date = {
                relative: {
                    type: values.relativeDateType,
                    length: values.relativeDateCount,
                    includeToday: values.includeToday
                },
                specific: null,
                weekDays: values.weekDays
            };

            if (values.relativeDateType === RelativeDateUnitOptions.LastNHours) {
                updatedDateTimeFilter.time = null;
            }
        }

        if (
            values.type === DateRangeType.Specific ||
            (values.type === DateRangeType.Relative && values.relativeDateType === RelativeDateUnitOptions.LastNDays)
        ) {
            updatedDateTimeFilter.time = {
                type: values.timeType,
                startTime: moment(values.startTime).format('HH:mm'),
                endTime: moment(values.endTime).format('HH:mm')
            };
        }

        formik.setFieldValue('dateTimeFilter', updatedDateTimeFilter);
    };

    const handleTabChange = (_, tab) => {
        setActiveTab(tab);
    };

    const setShouldOverrideDashboardDateTime = useCallback(
        (event) => {
            if (!event.target.checked) {
                formik.setFieldValue('dateTimeFilter', formik.initialValues.dateTimeFilter);
                setHasDateTimeErrors(false);
            }

            formik.setFieldValue('overrideDashboardDateTime', event.target.checked);
        },
        [formik]
    );

    const selectedBorderColor = theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark;

    const selectedAvatarStyle = {
        width: 64,
        height: 64,
        ...theme.typography.commonAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.light
        }
    };

    return (
        <Dialog maxWidth="lg" fullWidth open={isEditTimeSeriesDialogOpen}>
            <div className="edit-timeseries-dialog-container">
                <div className="edit-timeseries-dialog-header">
                    <DialogTitle sx={{ padding: 0, paddingLeft: 3 }}>Edit Widget</DialogTitle>
                    <IconButton sx={{ marginRight: 2 }} aria-label="close" onClick={toggleEditTimeSeriesDialogOpen}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Tabs onChange={handleTabChange} value={activeTab} centered>
                    <Tab
                        value={ViewTabs.Metrics}
                        label={
                            <span>
                                Metrics{' '}
                                {!formik.isValid || metricSubsetError ? (
                                    <IconAlertTriangle color={theme.palette.error.main} size="20px" stroke={2} />
                                ) : (
                                    ''
                                )}
                            </span>
                        }
                    />
                    <Tab
                        value={ViewTabs.DateTime}
                        label={
                            <span>
                                Date/Time{' '}
                                {hasDateTimeErrors ? <IconAlertTriangle color={theme.palette.error.main} size="20px" stroke={2} /> : ''}
                            </span>
                        }
                    />
                </Tabs>
                <form className="edit-timeseries-dialog-content" onSubmit={formik.handleSubmit}>
                    <div className="edit-timeseries-tab">
                        {activeTab === ViewTabs.Metrics ? (
                            <>
                                <TextField
                                    sx={{ width: '50%' }}
                                    id="widgetName"
                                    name="widgetName"
                                    label="Name"
                                    variant="outlined"
                                    value={formik.values.widgetName}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.widgetName)}
                                    helperText={formik.errors.widgetName}
                                />
                                <FormControl fullWidth style={{ width: '50%' }}>
                                    <InputLabel id="group-time-by-select-label">Group Time By</InputLabel>
                                    <Select
                                        labelId="group-time-by-select-label"
                                        id="groupTimeBy"
                                        name="groupTimeBy"
                                        value={formik.values.groupTimeBy}
                                        onChange={setGroupTimeBy}
                                        label="Group Time By"
                                    >
                                        {Object.entries(allowedTimeseriesWidgetConfig?.allowedTimeGroupingOptions ?? []).map(
                                            ([item, value]) => (
                                                <MenuItem key={`group-time-by-option_${item}`} value={item}>
                                                    {value.displayName}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ width: '50%' }}>
                                    <InputLabel id="data-type-select-label">Type</InputLabel>
                                    <Select
                                        labelId="data-type-select-label"
                                        id="type"
                                        name="type"
                                        value={formik.values.type}
                                        onChange={handleMetricCategoryTypeChange}
                                        label="Type"
                                    >
                                        {Object.entries(allowedTimeseriesWidgetConfig?.allowedMetricCategories ?? []).map(
                                            ([item, value]) => (
                                                <MenuItem key={`data-type-option_${item}`} value={item}>
                                                    {value.displayName}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                                <InputLabel id="graph-type-section">Graph Type</InputLabel>
                                <div className="graph-type-container">
                                    {Object.entries(
                                        allowedTimeseriesWidgetConfig?.allowedMetricCategories[formik.values.type]?.allowedGraphTypes ?? []
                                    ).map(([item, value]) => {
                                        return (
                                            <Tooltip key={item} title={value.displayName}>
                                                <Avatar
                                                    sx={{
                                                        ...selectedAvatarStyle,
                                                        border: formik.values.graphType === item ? `5px solid ${selectedBorderColor}` : 0
                                                    }}
                                                    alt={value.displayName}
                                                    src={getIconForChartType(item, theme.palette.mode)}
                                                    variant="rounded"
                                                    onClick={() => {
                                                        formik.setFieldValue('graphType', item);
                                                    }}
                                                />
                                            </Tooltip>
                                        );
                                    })}
                                </div>
                                {formik.values.type === TimeSeriesMetricsCategory.QueueCallCounts && (
                                    <FormControl fullWidth sx={{ width: '50%' }}>
                                        <InputLabel id="group-by-select-label">Group By</InputLabel>
                                        <Select
                                            labelId="group-by-select-label"
                                            id="groupDataBy"
                                            name="groupDataBy"
                                            value={formik.values.groupDataBy}
                                            onChange={formik.handleChange}
                                            label="Group By"
                                        >
                                            {Object.entries(
                                                allowedTimeseriesWidgetConfig?.allowedMetricCategories[formik.values.type]
                                                    ?.allowedDataGroupingOptions ?? []
                                            ).map(([item, value]) => (
                                                <MenuItem key={`group-by-option_${item}`} value={item}>
                                                    {value.displayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                {formik.values.type !== TimeSeriesMetricsCategory.QueueCallCounts && (
                                    <>
                                        <InputLabel id="pick-metrics-label">Metrics</InputLabel>
                                        <MetricsSubsetGrid
                                            fieldsConfig={fieldsConfig}
                                            setFieldsConfig={setFieldsConfig}
                                            allowedMetricsConfig={
                                                allowedTimeseriesWidgetConfig?.allowedMetricCategories[formik.values.type]
                                                    ?.allowedMetrics ?? {}
                                            }
                                            maxFieldCount={formik.values.type?.toLowerCase().includes('agent') ? 1 : null}
                                            setHasError={setMetricSubsetError}
                                            isReorderable={false}
                                            disabled={formik.values.type === TimeSeriesMetricsCategory.CallsAnsweredByAgent}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <FormControlLabel
                                    sx={{ whiteSpace: 'nowrap' }}
                                    control={
                                        <Checkbox
                                            checked={formik.values.overrideDashboardDateTime}
                                            onChange={setShouldOverrideDashboardDateTime}
                                        />
                                    }
                                    label="Override Dashboard Date/Time"
                                />
                                {formik.values.overrideDashboardDateTime && (
                                    <DateTimeFilter
                                        initialValues={formik.values.dateTimeFilter}
                                        setDateTimeFilterValues={setDateTimeFilterValues}
                                        setHasDateTimeErrors={setHasDateTimeErrors}
                                        selectedQueue={selectedQueue}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                        <Button variant="text" color="error" onClick={toggleEditTimeSeriesDialogOpen}>
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={saveDisabled || !formik.isValid} type="submit">
                            Save
                        </Button>
                    </Stack>
                </form>
            </div>
        </Dialog>
    );
}
