import React, { useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { useSelector } from 'store';

const getParentMenuItem = (templateType) => {
    const reportingSavedMenu = {
        id: 'report-templates',
        title: 'My Saved Templates',
        type: 'collapse',
        url: `/reporting/reports?templateType=saved`
    };

    const reportingSharedMenu = {
        id: 'report-templates',
        title: 'Templates Shared With Me',
        type: 'collapse',
        url: `/reporting/reports?templateType=shared`
    };

    const reportingStandardMenu = {
        id: 'report-templates',
        title: 'Standard Templates',
        type: 'collapse',
        url: `/reporting/reports?templateType=standard`
    };

    const reportingMenu = {
        id: 'report-templates',
        title: <FormattedMessage id="report-templates" />,
        type: 'collapse',
        url: '/reporting/reports'
    };

    switch (templateType) {
        case 'saved':
            return reportingSavedMenu;

        case 'shared':
            return reportingSharedMenu;

        case 'standard':
            return reportingStandardMenu;

        default:
            return reportingMenu;
    }
};

const getParentConnectorMenuItem = () => {
    return {
        id: 'connectors',
        title: 'Connectors',
        type: 'collapse',
        url: `/administration/connectors?featureType=connectors`
    };
};

const getParentRCMenuItem = () => {
    return {
        id: 'remoteCollections',
        title: 'Cloud Connect Servers',
        type: 'collapse',
        url: `/administration/connectors?featureType=cloudConnectServers`
    };
};

const getParentDirImportMenuItem = () => {
    return {
        id: 'dirImports',
        title: 'Directory Imports',
        type: 'collapse',
        url: `/administration/connectors?featureType=directoryImports`
    };
};

const getCustomizeTitle = (type, title) => {
    switch (type) {
        case 'edit':
            return title ? `Edit ${title}` : 'Edit Template';
        case 'copy':
            return title ? `Copy ${title}` : 'Copy Template';
        default:
            return title ? `Customize ${title}` : 'Customize Template';
    }
};

/** --------------------- Path Regular Expressions - Begin ----------------------- */

/** ------------------------- Administration - Begin --------------------------- */
const administrationMenusRegex = /^\/administration/i;

const connectorRegex = /^\/administration\/connectors\/connectorTemplate(\/[0-9]+)?$/i;
const callQueuePathRegex =
    /^\/administration\/connectors\/connectorTemplate(\/[0-9]+)\/queues\/(?:(?:[0-9a-f]){8}-(?:[0-9a-f]){4}-(?:[0-9a-f]){4}-(?:[0-9a-f]){4}-(?:[0-9a-f]){12})$/i;
const remoteCollectionsRegex = /^\/administration\/connectors\/RCTemplate(\/[0-9]+)?$/i;
const dirImportsRegex = /^\/administration\/connectors\/DirectoryImport(\/[0-9]+)?$/i;
const dirImportsPreviewRegex = /^\/administration\/connectors\/DirectoryImport(\/[0-9]+)?\/preview$/i;

/** ------------------------- Administration - End --------------------------- */

/** --------------------------- Reporting - Begin ----------------------------- */
const reportingMenusRegex = /^\/reporting/i;
// const getCustomizeTitle = (title) => (title ? `Customize ${title}` : 'Customize Template');
const getCustomizeReportTitle = (title) => (title ? `${title} Report` : 'Template Report');
const getCustomizePreviewTitle = (title) => (title ? `${title} Preview` : 'Template Preview');

const customizationPreviewRegex = /^\/reporting\/reports\/customization\/[0-9]+\/preview$/i;
const copyPreviewRegex = /^\/reporting\/reports\/customization\/[0-9]+\/copy\/preview$/i;
const editPreviewRegex = /^\/reporting\/reports\/customization\/[0-9]+\/edit\/preview$/i;

const customizationEditRegex = /^\/reporting\/reports\/customization\/[0-9]+\/edit$/i;
const customizationCopyRegex = /^\/reporting\/reports\/customization\/[0-9]+\/copy$/i;
const customizationRegex = /^\/reporting\/reports\/customization\/[0-9]+$/i;

const customizationRunNowwRegex = /^\/reporting\/reports\/customization\/[0-9]+\/report$/i;
const runNowwRegex = /^\/reporting\/reports\/[0-9]+\/report$/i;

const runNowfromHistoryRegex = /^\/reporting\/reports\/history\/[0-9]+\/report$/i;

const copyRunNowwRegex = /^\/reporting\/reports\/customization\/[0-9]+\/copy\/report$/i;
const editRunNowwRegex = /^\/reporting\/reports\/customization\/[0-9]+\/edit\/report$/i;

/** --------------------------- Reporting - End ----------------------------- */

/** --------------------- Path Regular Expressions - End ----------------------- */

const getReportCustomizationPreviewConfig = (templateCategory, title, params, templateType, type) => {
    const item = {
        id: 'report-templates-preview-single',
        title: getCustomizePreviewTitle(title),
        type: 'item'
    };
    return {
        item,
        menu: [
            getParentMenuItem(templateType),
            {
                id: 'report-templates-customize',
                title: getCustomizeTitle(type, title),
                type: 'collapse',
                url: type
                    ? `/reporting/reports/customization/${params.id}/${type}?templateType=${templateType}&templateCategory=${templateCategory}`
                    : `/reporting/reports/customization/${params.id}?templateType=${templateType}&templateCategory=${templateCategory}`
            }
        ],
        showCustomBreadcrumb: true
    };
};

const getReportCustomizationConfig = (title, templateType, type) => {
    const item = {
        id: 'report-templates-customize-single',
        title: getCustomizeTitle(type, title),
        type: 'item'
    };
    return {
        item,
        menu: [getParentMenuItem(templateType)],
        showCustomBreadcrumb: true
    };
};

const getReportConfig = (title, params, templateType) => {
    const item = {
        id: 'report-templates-single',
        title: getCustomizeReportTitle(title),
        type: 'item'
    };
    return {
        item,
        menu: [getParentMenuItem(templateType)],
        showCustomBreadcrumb: true
    };
};

const getHistoryReportConfig = (title, params, templateType) => {
    const item = {
        id: 'report-templates-single',
        title: getCustomizeReportTitle(title),
        type: 'item'
    };
    const reportHistory = {
        id: 'report-templates-history',
        title: 'Report History',
        type: 'collapse',
        url: '/reporting/report-history'
    };
    return {
        item,
        menu: [reportHistory],
        showCustomBreadcrumb: true
    };
};

const getReportCustomizationReportConfig = (title, params, templateType, type) => {
    const item = {
        id: 'report-templates-runnow-single',
        title: getCustomizeReportTitle(title),
        type: 'item'
    };
    return {
        item,
        menu: [
            getParentMenuItem(templateType),
            {
                id: 'report-templates-customize',
                title: getCustomizeTitle(type, title),
                type: 'collapse',

                url: type
                    ? `/reporting/reports/customization/${params.id}/${type}?templateType=${templateType}`
                    : `/reporting/reports/customization/${params.id}?templateType=${templateType}`
            }
        ],
        showCustomBreadcrumb: true
    };
};

const getConnectorName = (title, params) => {
    const item = {
        id: 'administration-connectors-new-connector',
        title,
        type: 'item'
    };
    return {
        item,
        menu: [getParentConnectorMenuItem(title)],
        showCustomBreadcrumb: true
    };
};

// Get the Call Queue Parent Connector Item
const getCallQueueParentConnectorItem = (connectorName, connectorId) => {
    return {
        id: 'administration-connectors-connector',
        title: connectorName,
        type: 'collapse',
        url: `/administration/connectors/connectorTemplate/${connectorId}`
    };
};

// Get the Queue Item with the Connectors and parent Connector menus
const getCallQueueItemAndMenu = (title, params, parentTitles) => {
    const item = {
        id: 'administration-connectors-queues',
        title,
        type: 'item'
    };
    return {
        item,
        menu: [
            getParentConnectorMenuItem(),
            getCallQueueParentConnectorItem((parentTitles ?? []).length > 0 ? parentTitles[0] : 'Unknown Connector', params.id)
        ],
        showCustomBreadcrumb: true
    };
};

const getRemoteCollectionName = (title, params) => {
    const item = {
        id: 'administration-connectors-new-RC',
        title,
        type: 'item'
    };
    return {
        item,
        menu: [getParentRCMenuItem(title)],
        showCustomBreadcrumb: true
    };
};

const getDirectoryImportName = (title, params) => {
    const item = {
        id: 'administration-dirImports-new-import',
        title,
        type: 'item'
    };
    return {
        item,
        menu: [getParentDirImportMenuItem(title)],
        showCustomBreadcrumb: true
    };
};

const getDirectoryImportPreviewName = (title, params) => {
    const item = {
        id: 'administration-dirImports-preview',
        title: `${title} Preview`,
        type: 'item'
    };
    return {
        item,
        menu: [
            getParentDirImportMenuItem(),
            {
                id: 'directory-import-preview',
                title,
                type: 'collapse',
                url: `/administration/connectors/DirectoryImport/${params.id}`
            }
        ],
        showCustomBreadcrumb: false
    };
};

const getMenuAndItem = (pathname, title, params, templateType, parentTitles, templateCategory) => {
    if (pathname) {
        /** ------------------- Administration - Begin --------------------- */
        if (administrationMenusRegex.test(pathname)) {
            // Connector Url
            if (connectorRegex.test(pathname)) {
                return getConnectorName(title, params);
            }

            // Call Queue Url
            if (callQueuePathRegex.test(pathname)) {
                return getCallQueueItemAndMenu(title, params, parentTitles);
            }

            // Remote Collection Url
            if (remoteCollectionsRegex.test(pathname)) {
                return getRemoteCollectionName(title, params);
            }

            // Directory Import Url
            if (dirImportsRegex.test(pathname)) {
                return getDirectoryImportName(title, params);
            }

            // Directory Import Preview
            if (dirImportsPreviewRegex.test(pathname)) {
                return getDirectoryImportPreviewName(title, params);
            }
        }

        /** ------------------- Administration - End --------------------- */

        /** ------------------- REPORTS - Begin ----------------------- */
        if (reportingMenusRegex.test(pathname)) {
            // Report Customization URL Test
            if (customizationRegex.test(pathname)) {
                return getReportCustomizationConfig(title, templateType);
            }

            // Report Customization Edit URL
            if (customizationEditRegex.test(pathname)) {
                return getReportCustomizationConfig(title, templateType, 'edit');
            }

            // Report Customization Copy Url
            if (customizationCopyRegex.test(pathname)) {
                return getReportCustomizationConfig(title, templateType, 'copy');
            }

            // Report Preview Customization Url
            if (customizationPreviewRegex.test(pathname)) {
                return getReportCustomizationPreviewConfig(templateCategory, title, params, templateType);
            }

            // Report Preview Customization - Copy Url
            if (copyPreviewRegex.test(pathname)) {
                return getReportCustomizationPreviewConfig(templateCategory, title, params, templateType, 'copy');
            }

            // Report Preview Customization - Edit Url
            if (editPreviewRegex.test(pathname)) {
                return getReportCustomizationPreviewConfig(templateCategory, title, params, templateType, 'edit');
            }

            // Reports Run Now Customization Url
            if (customizationRunNowwRegex.test(pathname)) {
                return getReportCustomizationReportConfig(title, params, templateType);
            }

            // Reports Run Now Customization - Copy Url
            if (copyRunNowwRegex.test(pathname)) {
                return getReportCustomizationReportConfig(title, params, templateType, 'copy');
            }

            // Reports Run Now Customization - Edit Url
            if (editRunNowwRegex.test(pathname)) {
                return getReportCustomizationReportConfig(title, params, templateType, 'edit');
            }

            // Report Run Now Url
            if (runNowwRegex.test(pathname)) {
                return getReportConfig(title, params, templateType);
            }

            // Report Run Now History Regex
            if (runNowfromHistoryRegex.test(pathname)) {
                return getHistoryReportConfig(title, params, templateType);
            }
        }
        /** ------------------- REPORTS - End ----------------------- */
    }

    return { showCustomBreadcrumb: false };
};

const useCustomBreadcrumb = () => {
    const location = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const templateType = searchParams.get('templateType');
    const templateCategory = searchParams.get('templateCategory');
    const breadcrumbData = useSelector((state) => state.breadcrumb);
    const title = breadcrumbData.title;
    const parentTitles = breadcrumbData.parentTitles;

    const menuAndItem = useMemo(
        () => getMenuAndItem(location.pathname, title, params, templateType, parentTitles, templateCategory),
        [title, location.pathname, params, templateType, parentTitles]
    );

    return menuAndItem;
};

export default useCustomBreadcrumb;
