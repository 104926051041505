// action - state management
import {
    LOGIN,
    LOGOUT,
    REGISTER,
    SET_TENANT_INFO,
    SET_FUSIONAUTH_CONFIG,
    SET_RECOVERY_CODES,
    SET_MFA_VERIFICATION_INFO,
    SET_AUTH_INFO,
    SET_TENANT_CONFIG,
    EVALUATE_FEATURES,
    SET_SUBSCRIPTION_DETAILS
} from './actions';

import { APP_FEATURES } from './constant';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    loginType: '',
    isInitialized: false,
    tenant: null,
    fusionAuth: null,
    fusionAuthConfig: null,
    recoveryCodes: null,
    mfaVerificationInfo: null,
    user: null,
    authInfo: null, // entire authentication info including auth, user and fusionAuthConfig used for generating a transfer token for non-sql logins.
    appFeatures: {}, // A map of current application features, indicating whether the feature is enabled or not,
    subscriptionDetails: null
};

function evaluateFeatures(currentAppFeatures, tenant) {
    const appFeatures = { ...currentAppFeatures };
    appFeatures[APP_FEATURES.Queues] = tenant && tenant.tenantData.queueLicensesCount > 0;
    return appFeatures;
}

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user, loginType } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                loginType
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                subscriptionDetails: null
            };
        }
        case SET_TENANT_INFO: {
            const { tenant, fusionAuth, loginType } = action.payload;
            const appFeatures = evaluateFeatures(state.appFeatures, tenant);
            return {
                ...state,
                tenant,
                fusionAuth,
                loginType,
                appFeatures
            };
        }
        case SET_TENANT_CONFIG: {
            const tenant = action.payload;
            const appFeatures = evaluateFeatures(state.appFeatures, tenant);
            return {
                ...state,
                tenant,
                appFeatures
            };
        }
        case SET_FUSIONAUTH_CONFIG: {
            const { fusionAuthConfig } = action.payload;
            return {
                ...state,
                fusionAuthConfig
            };
        }
        case SET_RECOVERY_CODES: {
            const { recoveryCodes } = action.payload;
            return {
                ...state,
                recoveryCodes
            };
        }
        case SET_MFA_VERIFICATION_INFO: {
            // const { mfaVerificationInfo } = action.payload;
            return {
                ...state,
                mfaVerificationInfo: action.payload
            };
        }
        case SET_AUTH_INFO: {
            return { ...state, authInfo: action.payload };
        }
        case EVALUATE_FEATURES: {
            const appFeatures = evaluateFeatures(state.appFeatures, state.tenant);
            return {
                ...state,
                appFeatures
            };
        }
        case SET_SUBSCRIPTION_DETAILS: {
            return {
                ...state,
                subscriptionDetails: action.payload
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
