import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// reducer - state management
import {
    LOGIN,
    LOGOUT,
    RESET_STORE,
    SET_TENANT_INFO,
    SET_FUSIONAUTH_CONFIG,
    SET_RECOVERY_CODES,
    SET_MFA_VERIFICATION_INFO,
    SET_AUTH_INFO,
    SET_TENANT_CONFIG,
    SET_SUBSCRIPTION_DETAILS
} from 'store/actions';
import accountReducer from 'store/accountReducer';
import { useDispatch } from 'store';

// project imports
import Loader from 'ui-component/Loader';
import axios, { axiosCustom, axiosReportService, axiosNotificationService, axiosMSTeamsCQService, axiosUserDataService } from 'utils/axios';
import { identifyPendo } from 'utils/pendo';
import { UserRoles } from 'store/constant';

// constant
const initialState = {
    isLoggedIn: false,
    loginType: '',
    isInitialized: false,
    tenant: null,
    fusionAuth: null,
    fusionAuthConfig: null,
    recoveryCodes: null,
    mfaVerificationInfo: null,
    user: null,
    authInfo: null,
    subscriptionDetails: null
};

const verifyToken = ({ token, expires }) => {
    if (!token) {
        return false;
    }
    // const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return Date.parse(expires) > Date.now();
};

const setStorage = (loginToken, initialLogin) => {
    if (loginToken) {
        const strLoginToken = JSON.stringify(loginToken);
        localStorage.setItem('loginToken', strLoginToken);
        if (initialLogin && loginToken?.isReportAdmin) {
            const strSelectedUser = JSON.stringify({
                label: 'My',
                value: loginToken?.ownerId
            });
            localStorage.setItem('selectedUser', strSelectedUser);
        }
        axios.defaults.headers.common.Authorization = `Bearer ${loginToken.token}`;
        axiosReportService.defaults.headers.common.Authorization = `Bearer ${loginToken.token}`;
        axiosNotificationService.defaults.headers.common.Authorization = `Bearer ${loginToken.token}`;
        axiosMSTeamsCQService.defaults.headers.common.Authorization = `Bearer ${loginToken.token}`;
        axiosUserDataService.defaults.headers.common.Authorization = `Bearer ${loginToken.token}`;
    } else {
        localStorage.removeItem('selectedUser');
        localStorage.removeItem('loginToken');
        localStorage.setItem('forceLogout', true);
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const storeDispatch = useDispatch();
    const navigate = useNavigate();

    const getSubscriptionDetails = useCallback(
        async (tenantId, tenantName) => {
            try {
                if (!tenantId && !tenantName) {
                    dispatch({
                        type: SET_SUBSCRIPTION_DETAILS,
                        payload: 'no-subscription'
                    });
                }
                const subscriptionResponse = await axios.get(`/users/subscriptiondetail?tenantId=${tenantId}&tenantName=${tenantName}`);
                if (subscriptionResponse.data.success) {
                    dispatch({
                        type: SET_SUBSCRIPTION_DETAILS,
                        payload: subscriptionResponse.data.data
                    });
                } else {
                    dispatch({
                        type: SET_SUBSCRIPTION_DETAILS,
                        payload: 'no-subscription'
                    });
                    console.log(`Error fetching subscription details for tenant ${tenantName}`);
                }
            } catch (ex) {
                console.log(`Error fetching subscription details for tenant ${tenantName}`);
            }
        },
        [dispatch]
    );

    const retrieveTenantConfigForClient = useCallback(
        async (initialLogin) => {
            try {
                const uri = `/tenant/config/client?initialLogin=${initialLogin}`;
                const response = await axios.get(uri);
                const tenant = response.data;
                dispatch({
                    type: SET_TENANT_CONFIG,
                    payload: tenant
                });
                // get the subscription details for the tenant
                getSubscriptionDetails(tenant.tenantId, tenant.name);
            } catch (error) {
                // For now silently logging the error
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else if (error.code) {
                    console.log(`Error code ${error?.code}`);
                } else {
                    console.log(error.message);
                }
            }
        },
        [dispatch]
    );

    useEffect(() => {
        // Add Report service interceptor to handle 401 and perform logout
        const errorCallback = (error) => {
            if (error?.response?.status === 401) {
                console.log('401 error, Redirecting to login page from JWTProvider');
                dispatch({
                    type: LOGOUT
                });
            }
            return Promise.reject(error);
        };

        axiosReportService.interceptors.response.use((response) => response, errorCallback);
        axiosNotificationService.interceptors.response.use((response) => response, errorCallback);
        axiosMSTeamsCQService.interceptors.response.use((response) => response, errorCallback);
        axiosUserDataService.interceptors.response.use((response) => response, errorCallback);
    }, []);

    useEffect(() => {
        const init = async () => {
            try {
                const loginToken = JSON.parse(window.localStorage.getItem('loginToken'));
                if (loginToken && verifyToken(loginToken)) {
                    setStorage(loginToken, false);
                    // if non-sql login, use the /users/current/auth to get all information needed for retrieving the transfer token
                    // otherwise use the /users/current end point for sql auth
                    // appending the current time stamp to make sure that the browser doesn't use the cached response for this particular end point
                    const endpoint = loginToken.loginType === 'sql' ? '/users/current' : `/users/current/auth?_=${new Date().getTime()}`;
                    const response = await axios.get(endpoint);
                    // check if username is empty and role is SYSADMIN
                    // if that's the case, set username to the value stored in session
                    const { userName, clientId, ownerId, orgId, roles } = response.data.user || response.data;
                    let loggedInUser = userName;
                    if (userName === '' && roles?.includes('SYSADMIN')) {
                        loggedInUser = loginToken.username;
                    }
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            loginType: loginToken.loginType,
                            user: {
                                userName: loggedInUser,
                                clientId,
                                ownerId,
                                orgId,
                                roles
                            }
                        }
                    });
                    // storeDispatch(fetchPreferences());

                    // Retrieve the current tenant config again
                    await retrieveTenantConfigForClient(false);
                    if (loginToken.loginType !== 'sql') {
                        // store the response object as is to be sent for retrieving the transfer token later when redirecting to legacy
                        dispatch({
                            type: SET_AUTH_INFO,
                            payload: response.data
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, [storeDispatch, retrieveTenantConfigForClient]);

    const login = async (clientId, username, password) => {
        const response = await axiosCustom.post('/users/auth', { clientId, username, password }).catch((error) => {
            if (error?.status === 'INVALID_CREDENTIALS') {
                throw new Error(
                    'The Username and Password combination are invalid, please ensure you typed it accurately and try again. If you are still unable to login, you can open a support ticket for assistance.'
                );
            }
        });
        if (response.data?.message?.toLowerCase().includes('the specified client was not found in the database')) {
            throw new Error(`Please Enter a Valid Customer ID`);
        }
        // check if response status is 401 (unauthorized - due to wrong password or username)
        try {
            const { user, auth } = response.data;
            setStorage(
                {
                    token: auth.token,
                    expires: auth.expires,
                    transfer: auth.transfer,
                    clientId: user.clientId,
                    username: user.userName,
                    ownerId: user.ownerId,
                    loginType: 'sql',
                    isReportAdmin: user.roles?.includes(UserRoles.SystemAdmin) || user.roles?.includes(UserRoles.ReportAdmin)
                },
                true
            );
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    loginType: 'sql',
                    user
                }
            });
            // initialize pendo
            identifyPendo(user);

            // Query the Tenant config for CustomerId login
            dispatch(retrieveTenantConfigForClient(true));
        } catch (ex) {
            throw new Error(`Invalid credentials`);
        }
    };

    const logout = async () => {
        // if sso, logout from FusionAuth
        if (state.loginType === 'sso') {
            await axios.get('/logout');
            const ssoLogoutUrl = localStorage.getItem('faLogoutUrl').replace('%REDIRECT_URI%', process.env.REACT_APP_SSO_LOGOUT_URL);
            window.location.replace(ssoLogoutUrl);
        }
        if (state.loginType === 'idp') {
            await axios.get('/logout');
        }
        setStorage(null, false);
        dispatch({ type: LOGOUT });
        storeDispatch({ type: RESET_STORE });
        localStorage.removeItem('forceLogout');
        localStorage.removeItem('loginToken');
    };

    const retrieveTenantConfig = async (email) => {
        const response = await axios.get(`/tenant/config?email=${encodeURIComponent(email)}`).catch((error) => {
            if (error.response) {
                throw new Error(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                throw new Error(`${error.request}`);
            } else if (error.code) {
                throw new Error(`Error code ${error?.code}`);
            } else {
                throw new Error(error.message);
            }
        });
        try {
            const { tenant, fusionAuth } = response.data;
            if (tenant.tenantData.isSso) {
                const faSSORedirectUrl = fusionAuth.authUrl.replace('%REDIRECT_URI%', process.env.REACT_APP_SSO_REDIRECT_URL);
                window.location.replace(faSSORedirectUrl);
            } else {
                dispatch({
                    type: SET_TENANT_INFO,
                    payload: {
                        tenant,
                        fusionAuth,
                        loginType: tenant.tenantData.isSso ? 'sso' : 'idp'
                    }
                });
                // get the subscription details for the tenant
                getSubscriptionDetails(tenant.tenantId, tenant.name);
                navigate(`/login?type=fa&userName=${encodeURIComponent(email)}`);
            }
        } catch (ex) {
            throw new Error(ex.message);
        }
    };

    const setTenantConfigInfo = async (userName) => {
        const response = await axios.get(`/tenant/config?email=${encodeURIComponent(userName)}`).catch((error) => {
            if (error.response) {
                throw new Error(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                throw new Error(`${error.request}`);
            } else {
                throw new Error(`Error - ${error.message}`);
            }
        });
        try {
            const { tenant, fusionAuth } = response.data;
            dispatch({
                type: SET_TENANT_INFO,
                payload: {
                    tenant,
                    fusionAuth,
                    loginType: tenant.tenantData.isSso ? 'sso' : 'idp'
                }
            });
            // store the fusionAuth log out url in the session so that when the app refreshes, the sso log out is still successful
            localStorage.setItem('faLogoutUrl', fusionAuth.logoutUrl);
            // get the subscription details for the tenant
            getSubscriptionDetails(tenant.tenantId, tenant.name);
        } catch (ex) {
            throw new Error(ex.message);
        }
    };

    const loginSSO = async ({ state, code, redirectUri }) => {
        const response = await axios.post(`/users/auth/sso`, { state, code, redirectUri }).catch((error) => {
            throw new Error(error);
        });
        try {
            if (response?.data?.message) throw response.data.message;
            const { user, auth } = response.data;
            setStorage(
                {
                    token: auth.token,
                    expires: auth.expires,
                    transfer: auth.transfer,
                    clientId: user.clientId,
                    username: user.userName,
                    ownerId: user.ownerId,
                    loginType: 'sso',
                    isReportAdmin: user.roles?.includes(UserRoles.SystemAdmin) || user.roles?.includes(UserRoles.ReportAdmin)
                },
                true
            );
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    loginType: 'sso',
                    user
                }
            });
            // store the response object as is to be sent for retrieving the transfer token later when redirecting to legacy
            dispatch({
                type: SET_AUTH_INFO,
                payload: response.data
            });
            setTenantConfigInfo(user.userName);
            // initialize pendo
            // initializePendo(user);
        } catch (ex) {
            throw new Error(ex);
        }
    };

    const loginFaIdp = async (username, password) => {
        const response = await axiosCustom.post(`/users/auth/idp`, { username, password }).catch(async (error) => {
            if (error.status === 'TWO_FACTOR_AUTH') {
                // set state and load MFA verification screen
                dispatch({
                    type: SET_MFA_VERIFICATION_INFO,
                    payload: {
                        tenantId: state.tenant.tenantData.fusionAuthTenantId,
                        userName: username,
                        twoFactorId: error.twoFactorId,
                        methodId: error.methods[0].id,
                        email: error.methods[0].email,
                        phoneNumber: error.methods[0].mobilePhone,
                        authenticator: error.methods[0].authenticator
                    }
                });
                if (error.methods[0].method !== 'authenticator') {
                    const response = await axios
                        .post(`/users/auth/mfa/send`, {
                            tenantId: state.tenant.tenantData.fusionAuthTenantId,
                            userName: username,
                            twoFactorId: error.twoFactorId,
                            methodId: error.methods[0].id
                        })
                        .catch((error) => {
                            console.log(error.message);
                        });
                    if (response) {
                        navigate(`/login/mfa-verify/${error.methods[0].method}`, { replace: true });
                    }
                } else {
                    navigate(`/login/mfa-verify/${error.methods[0].method}`, { replace: true });
                }
            } else if (error.status === 'INVALID_CREDENTIALS') {
                throw new Error(
                    `The Username and Password combination are invalid, please ensure you typed it accurately and try again.  If you are still unable to login, please reach out to your system administrator for assistance.`
                );
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        try {
            const { fusionAuthConfig } = response.data;
            if (fusionAuthConfig.status === 'PASSWORD_CHANGE_REQUIRED') {
                dispatch({
                    type: SET_FUSIONAUTH_CONFIG,
                    payload: {
                        fusionAuthConfig
                    }
                });
                navigate(`/login/change-password?userName=${encodeURIComponent(username)}`, { replace: true });
            } else if (fusionAuthConfig.status === 'OK') {
                // check to see if mfa turned on AND user needs to be registered.
                // if not proceed and complete the login process
                if (fusionAuthConfig.mfaOptions?.enabled && fusionAuthConfig.mfaOptions?.isUserToBeRegistered) {
                    dispatch({
                        type: SET_FUSIONAUTH_CONFIG,
                        payload: {
                            fusionAuthConfig: { ...fusionAuthConfig, mfaRegisterPwd: password }
                        }
                    });
                    navigate(`/login/mfa/register?userName=${encodeURIComponent(username)}`, { replace: true });
                } else {
                    const { auth, user } = response.data;
                    setStorage(
                        {
                            token: auth.token,
                            expires: auth.expires,
                            transfer: auth.transfer,
                            clientId: user.clientId,
                            username: user.userName,
                            ownerId: user.ownerId,
                            loginType: 'idp',
                            isReportAdmin: user.roles?.includes(UserRoles.SystemAdmin) || user.roles?.includes(UserRoles.ReportAdmin)
                        },
                        true
                    );
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            loginType: 'idp',
                            user,
                            fusionAuthConfig: null
                        }
                    });
                    // store the response object as is to be sent for retrieving the transfer token later when redirecting to legacy
                    dispatch({
                        type: SET_AUTH_INFO,
                        payload: response.data
                    });
                    // initialize pendo
                    // initializePendo(user);
                }
            }
        } catch (ex) {
            throw new Error('Invalid credentials');
        }
    };

    const loginFaMfa = async (userName, code, twoFactorId) => {
        const response = await axios.post(`/users/auth/idp`, { userName, code, twoFactorId }).catch(() => {
            throw new Error(`Login failed for user`);
        });
        try {
            const { data } = response;
            if (data.user && data.auth) {
                const { user, auth } = data;
                setStorage(
                    {
                        token: auth.token,
                        expires: auth.expires,
                        transfer: auth.transfer,
                        clientId: user.clientId,
                        username: user.userName,
                        ownerId: user.ownerId,
                        loginType: 'idp',
                        isReportAdmin: user.roles?.includes(UserRoles.SystemAdmin) || user.roles?.includes(UserRoles.ReportAdmin)
                    },
                    true
                );
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        loginType: 'idp',
                        user
                    }
                });
                // store the response object as is to be sent for retrieving the transfer token later when redirecting to legacy
                dispatch({
                    type: SET_AUTH_INFO,
                    payload: data
                });
            } else if (data.fusionAuthConfig) {
                const { fusionAuthConfig } = data;
                if (fusionAuthConfig.status === 'PASSWORD_CHANGE_REQUIRED') {
                    dispatch({
                        type: SET_FUSIONAUTH_CONFIG,
                        payload: {
                            fusionAuthConfig
                        }
                    });
                    navigate(`/login/change-password?userName=${encodeURIComponent(userName)}`, { replace: true });
                } else {
                    throw new Error(`Unexpected fusionAuthConfig status: ${fusionAuthConfig.status}`);
                }
            } else {
                throw new Error(`Unexpected response format`);
            }
        } catch (ex) {
            console.log(ex);
            throw new Error(`Login failed for user`);
        }
    };

    const changeFaPassword = async ({ tenantId, userName, currentPassword, newPassword, changePasswordId }) => {
        try {
            await axios
                .post(`/users/auth/idp/change-password`, { tenantId, userName, currentPassword, newPassword, changePasswordId })
                .catch((error) => {
                    if (error.status === 'INVALID_CREDENTIALS') {
                        throw new Error(`Change Password request failed. Invalid credentials passed in`);
                    } else if (error?.code) {
                        throw new Error(`Error code ${error.code}`);
                    } else {
                        throw new Error(`Change Password request failed`);
                    }
                });
            navigate(`/login`, { replace: true });
        } catch (ex) {
            throw new Error(ex.message);
        }
    };

    const verifyMfaCode = async ({ userName, password, tenantId, method, contact, code, secretBase32Encoded }) => {
        const response = await axios
            .post(`/users/auth/mfa/verify`, { userName, password, tenantId, method, contact, code, secretBase32Encoded })
            .catch((error) => {
                throw new Error(`Could not verify the code`);
            });
        try {
            if (response?.data) {
                dispatch({
                    type: SET_RECOVERY_CODES,
                    payload: {
                        recoveryCodes: response.data.recoveryCodes
                    }
                });
                navigate(`/login/recoveryCodes`, { replace: true });
            }
        } catch (ex) {
            throw new Error(`Couldn't verify the code`);
        }
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{ ...state, login, logout, retrieveTenantConfig, loginSSO, loginFaIdp, changeFaPassword, verifyMfaCode, loginFaMfa }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
