import { lazy } from 'react';

import { Outlet } from 'react-router-dom';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthUserName = Loadable(lazy(() => import('views/pages/authentication/Login')));
const AuthSSO = Loadable(lazy(() => import('views/pages/authentication/AuthSSO')));
const AuthChangePwd = Loadable(lazy(() => import('views/pages/authentication/ChangePassword')));
const MfaRegister = Loadable(lazy(() => import('views/pages/authentication/MfaRegister')));
const RecoveryCodes = Loadable(lazy(() => import('views/pages/authentication/RecoveryCodes')));
const MfaVerify = Loadable(lazy(() => import('views/pages/authentication/MfaVerify')));
const SubscriptionExpire = Loadable(lazy(() => import('views/pages/SubscriptionExpire')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <Outlet />
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MinimalLayout />
                </GuestGuard>
            ),
            children: [
                {
                    path: '/',
                    element: <AuthUserName />
                },
                {
                    path: '/login',
                    element: <AuthUserName />
                },
                {
                    path: '/login/sso',
                    element: <AuthSSO />
                },
                {
                    path: '/login/change-password',
                    element: <AuthChangePwd />
                },
                {
                    path: '/login/mfa/register',
                    element: <MfaRegister />
                },
                {
                    path: '/login/recoveryCodes',
                    element: <RecoveryCodes />
                },
                {
                    path: '/login/mfa-verify/:method',
                    element: <MfaVerify />
                },
                {
                    path: '/subscription-expired',
                    element: <SubscriptionExpire />
                }
            ]
        }
    ]
};

export default LoginRoutes;
